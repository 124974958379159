import { styled, TextField } from '@mui/material';

export const OverviewTitleStyled = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const TextFieldStyled = styled(TextField)({
  paddingBottom: '16px',
});
