import { styled, DialogTitle, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

export const DialogTitleStyled = styled(DialogTitle)({
  minHeight: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const TitleStyled = styled(Typography)({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
});

const baseIconStyles = {
  width: 20,
  height: 20,
};
export const LeftIconStyled = styled(KeyboardArrowLeftIcon)(baseIconStyles);

export const CloseIconStyled = styled(CloseIcon)(baseIconStyles);
