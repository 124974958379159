export interface GetTransactionsOptionsDto {
  pageNumber?: number;
  pageSize?: number;
}

export enum FundTransferMethods {
  WIRE = 'wire',
  WIRE_INTERNATIONAL = 'wire_international',
  ACH = 'ach',
  CREDIT_CARD = 'credit_card',
  EXTERNAL_PAYMENT = 'external_payment',
}

export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
  USDC = 'USDC',
}

export enum TransferTypes {
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL',
  ASSETS_BUY = 'ASSETS_BUY',
  ASSETS_SELL = 'ASSETS_SELL',
  WITHDRAWAL_STABLE_COIN = 'WITHDRAWAL_STABLE_COIN',
  DEPOSIT_STABLE_COIN = 'DEPOSIT_STABLE_COIN',
  DISTRIBUTION = 'DISTRIBUTION',
  EXTERNAL_PAYMENT = 'EXTERNAL_PAYMENT',
}

export enum TransferStatus {
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  SETTLED = 'SETTLED',
  REVERSED = 'REVERSED',
}

export interface GetTransactionsDataDto {
  id: string;
  type?: TransferTypes;
  status: TransferStatus;
  createdAt: Date;
  updatedAt: Date;
  currency?: Currency;
  total: number;
  value: number;
  fee?: number;
  fundTransferMethod?: FundTransferMethods;
}

export interface GetTransactionsResponseDto {
  meta: {
    currentPage: number;
    pageSize: number;
    totalPages: number;
    totalItems: number;
  };
  data: GetTransactionsDataDto[];
}
