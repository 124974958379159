import { Currency } from './Currency';

export enum AccountStatus {
  DRAFT = 'DRAFT',
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  CLOSED = 'CLOSED',
  FROZEN = 'FROZEN',
}

export interface CashAccount {
  cashAccountId: string;
  status: AccountStatus;
  investorId?: string;
  createdAt: string;
  updatedAt: string;
}

export enum CurrencyDisabledReason {
  CASH_BALANCE_DISABLED = 'CASH_BALANCE_DISABLED',
  DUAL_BALANCE_DISABLED = 'DUAL_BALANCE_DISABLED',
}

export enum Provider {
  ZEROHASH = 'ZEROHASH',
  BBVA = 'BBVA',
}

export interface ProviderSummary {
  provider: Provider;
  providerId: string;
}

export interface CashBalanceSummary {
  currency: Currency;
  status?: AccountStatus;
  disabled: boolean;
  reasons?: CurrencyDisabledReason[];
  providers: ProviderSummary[];
}

export interface CashBalance {
  available: number;
  total: number;
  locked: number;
  instantSettlementLocked?: number;
  currency: Currency;
}

export interface AccountSummary {
  cashAccount?: CashAccount;
  cashBalances: CashBalanceSummary[];
  balance?: CashBalance[];
}
