import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { CardContent, CardHeader, Typography, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Card,
  ServerDataGrid,
  Box,
  Pagination,
  Chip,
  Button,
  useNotification,
  ChipProps,
} from '@securitize/reactjs-cp-ui';
import { GridColDef } from '@mui/x-data-grid';
import { CurrencyFormat } from '@securitize/reactjs-sid-utils';
import { Translate } from '../../../i18n/Translate';
import {
  GlobalContext,
  GlobalContextType,
} from '../../../context/GlobalContext';
import { getAccountTransactions } from '../../../data/rest/account';
import {
  GetTransactionsResponseDto,
  TransferStatus,
  TransferTypes,
} from '../../../interfaces/AccountTransactions';
import { formatDate } from '../../../helpers/formatDate';
import { TranslationsKeyType } from '../../../i18n/translationsKeysType';
import { DEFAULT_CURRENCY_LOCALE } from '../../../i18n/locale';

interface TransactionsHistoryProps {
  investorId: string;
}

const TransactionTypeMap: Record<
  TransferTypes,
  { labelKey: TranslationsKeyType; icon: React.ReactNode }
> = {
  [TransferTypes.ASSETS_BUY]: {
    labelKey: 'HISTORY.GRID.COLUMN.TYPE.ASSETS_BUY',
    icon: <AddIcon />,
  },
  [TransferTypes.ASSETS_SELL]: {
    labelKey: 'HISTORY.GRID.COLUMN.TYPE.ASSETS_SELL',
    icon: <RemoveIcon />,
  },
  [TransferTypes.DEPOSIT]: {
    labelKey: 'HISTORY.GRID.COLUMN.TYPE.DEPOSIT',
    icon: <ArrowDownwardIcon />,
  },
  [TransferTypes.DEPOSIT_STABLE_COIN]: {
    labelKey: 'HISTORY.GRID.COLUMN.TYPE.DEPOSIT_STABLE_COIN',
    icon: <ArrowDownwardIcon />,
  },
  [TransferTypes.DISTRIBUTION]: {
    labelKey: 'HISTORY.GRID.COLUMN.TYPE.DISTRIBUTION',
    icon: <CallSplitIcon />,
  },
  [TransferTypes.EXTERNAL_PAYMENT]: {
    labelKey: 'HISTORY.GRID.COLUMN.TYPE.EXTERNAL_PAYMENT',
    icon: <AddIcon />,
  },
  [TransferTypes.WITHDRAWAL]: {
    labelKey: 'HISTORY.GRID.COLUMN.TYPE.WITHDRAWAL',
    icon: <ArrowDownwardIcon />,
  },
  [TransferTypes.WITHDRAWAL_STABLE_COIN]: {
    labelKey: 'HISTORY.GRID.COLUMN.TYPE.WITHDRAWAL_STABLE_COIN',
    icon: <ArrowDownwardIcon />,
  },
};

const TransactionStatusMapBuilder: (
  getTranslationByKey: GlobalContextType['getTranslationByKey'],
) => Record<TransferStatus, { text: string; color: ChipProps['color'] }> = (
  getTranslationByKey,
) => ({
  [TransferStatus.PENDING]: {
    text: getTranslationByKey('HISTORY.GRID.COLUMN.STATUS.PENDING'),
    color: 'warning',
  },
  [TransferStatus.CANCELLED]: {
    text: getTranslationByKey('HISTORY.GRID.COLUMN.STATUS.CANCELLED'),
    color: 'error',
  },
  [TransferStatus.SETTLED]: {
    text: getTranslationByKey('HISTORY.GRID.COLUMN.STATUS.SETTLED'),
    color: 'success',
  },
  [TransferStatus.REVERSED]: {
    text: getTranslationByKey('HISTORY.GRID.COLUMN.STATUS.REVERSED'),
    color: 'error',
  },
});

const getAmount = (amount: number, currency: string, type?: TransferTypes) => {
  if (amount === undefined || amount === null || Number.isNaN(amount)) {
    return '-';
  }

  const isCrypto =
    type === TransferTypes.WITHDRAWAL_STABLE_COIN ||
    type === TransferTypes.DEPOSIT_STABLE_COIN;
  const defaultCurrency = isCrypto ? 'USDC' : 'USD';

  try {
    const currencyFormat = new CurrencyFormat({
      currency: currency || defaultCurrency,
      isCrypto,
      overrideLocale: DEFAULT_CURRENCY_LOCALE,
    });

    return currencyFormat.format(amount);
  } catch (error) {
    return '-';
  }
};

const StyledTransactionTypeWrapper = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  borderRadius: '50%',
  backgroundColor: theme.palette.grey[300],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: 8,
}));

export const TransactionsHistory = ({
  investorId,
}: TransactionsHistoryProps) => {
  const { getTranslationByKey } = useContext(GlobalContext);
  const dataGridRef = useRef<{ refreshDataGrid: () => {} }>(null);
  const [sendNotification] = useNotification();
  const [transactionsRequestState, setTransactionsRequestState] = useState<{
    isLoading: boolean;
    error?: string;
  }>({ isLoading: false });

  const TransactionStatusMap = useMemo(
    () => TransactionStatusMapBuilder(getTranslationByKey),
    [getTranslationByKey],
  );

  const columns: GridColDef[] = useMemo<GridColDef[]>(
    () => [
      {
        field: 'type',
        flex: 1,
        headerName: getTranslationByKey('HISTORY.GRID.COLUMN.TYPE'),
        minWidth: 117,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ value }) => {
          const transactionType = TransactionTypeMap[value as TransferTypes];
          return (
            <>
              {transactionType?.icon && (
                <StyledTransactionTypeWrapper>
                  {transactionType.icon}
                </StyledTransactionTypeWrapper>
              )}

              {transactionType?.labelKey
                ? getTranslationByKey(transactionType.labelKey)
                : '-'}
            </>
          );
        },
      },
      {
        field: 'createdAt',
        flex: 1,
        headerName: getTranslationByKey('HISTORY.GRID.COLUMN.CREATION_DATE'),
        minWidth: 117,
        sortable: false,
        disableColumnMenu: true,
        valueGetter: (params) => formatDate(params.value),
      },
      {
        field: 'updatedAt',
        flex: 1,
        headerName: getTranslationByKey('HISTORY.GRID.COLUMN.LAST_UPDATE'),
        minWidth: 117,
        sortable: false,
        disableColumnMenu: true,
        valueGetter: (params) => formatDate(params.value),
      },
      {
        field: 'status',
        flex: 1,
        headerName: getTranslationByKey('HISTORY.GRID.COLUMN.STATUS'),
        minWidth: 117,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <Chip
            sx={{ fontSize: '13px' }}
            label={TransactionStatusMap[params.value as TransferStatus].text}
            variant="outlined"
            size="small"
            color={TransactionStatusMap[params.value as TransferStatus].color}
          />
        ),
      },
      {
        field: 'value',
        flex: 1,
        headerName: getTranslationByKey(
          'HISTORY.GRID.COLUMN.TRANSACTION_AMOUNT',
        ),
        minWidth: 117,
        sortable: false,
        disableColumnMenu: true,
        headerAlign: 'right',
        align: 'right',
        valueGetter: ({ row }) => getAmount(row.value, row.currency, row.type),
      },
      {
        field: 'fee',
        flex: 1,
        headerName: getTranslationByKey('HISTORY.GRID.COLUMN.FEE'),
        minWidth: 117,
        sortable: false,
        disableColumnMenu: true,
        headerAlign: 'right',
        align: 'right',
        valueGetter: ({ row }) => getAmount(row.fee, row.currency, row.type),
      },
      {
        field: 'total',
        flex: 1,
        headerName: getTranslationByKey('HISTORY.GRID.COLUMN.TOTAL'),
        minWidth: 117,
        sortable: false,
        disableColumnMenu: true,
        headerAlign: 'right',
        align: 'right',
        valueGetter: ({ row }) => getAmount(row.total, row.currency, row.type),
      },
    ],
    [getTranslationByKey, TransactionStatusMap],
  );

  const callGetTransactionHistory = useCallback(
    async (page: number, pageSize: number): Promise<Pagination> => {
      let transactionsResponse: GetTransactionsResponseDto = {
        data: [],
        meta: {
          totalItems: 0,
          currentPage: 1,
          totalPages: 0,
          pageSize: 0,
        },
      };

      setTransactionsRequestState({
        error: undefined,
        isLoading: true,
      });

      try {
        transactionsResponse = await getAccountTransactions(investorId, {
          pageNumber: page,
          pageSize,
        });

        setTransactionsRequestState({
          error: undefined,
          isLoading: false,
        });
      } catch (error: any) {
        const message =
          error.message || getTranslationByKey('HISTORY.GRID.ERROR');
        sendNotification({
          msg: message,
          variant: 'error',
        });
        setTransactionsRequestState({
          error: message,
          isLoading: false,
        });
      }

      return {
        data: transactionsResponse.data as any,
        totalItems: transactionsResponse.meta.totalItems,
      };
    },
    [
      setTransactionsRequestState,
      investorId,
      sendNotification,
      getTranslationByKey,
    ],
  );

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h5" fontSize={24}>
            <Translate translationKey="HISTORY.TITLE" />
          </Typography>
        }
      />
      <CardContent>
        <Box sx={{ height: 624, width: '100%', backgroundColor: 'white' }}>
          <ServerDataGrid
            ref={dataGridRef}
            columns={columns}
            gridLinesVisibility
            rowsPerPageOptions={[10, 25, 50]}
            rows={[]}
            initialPage={0}
            loadServerRows={callGetTransactionHistory}
            emptyConfig={{
              image: transactionsRequestState.error ? false : undefined,
              error: transactionsRequestState.error ? true : undefined,
              text: transactionsRequestState.error
                ? getTranslationByKey('HISTORY.GRID.ERROR')
                : getTranslationByKey('HISTORY.GRID.NO_RESULTS'),
              actionButton: transactionsRequestState.error ? (
                <Button
                  variant="text"
                  sx={{ zIndex: 1, mt: 0.5 }}
                  endIcon={<RefreshIcon />}
                  onClick={() => {
                    dataGridRef.current?.refreshDataGrid();
                  }}
                >
                  <Translate translationKey="HISTORY.GRID.REFRESH" />
                </Button>
              ) : undefined,
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
