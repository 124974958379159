import { styled, Stack, Typography, Chip } from '@mui/material';

export const StyledChip = styled(Chip)({
  fontSize: '13px',
});

export const StyledBalanceDetail = styled(Stack)(({ theme }) => ({
  width: '33%',
  alignItems: 'flex-start',
  flexDirection: 'column',
  gap: '8px',
}));

const DetailItemBaseStyles = {
  fontSize: '16px',
  lineHeight: '24px',
};

export const StyledTypographyDetailTitle = styled(Typography)(({ theme }) => ({
  ...DetailItemBaseStyles,
  color: theme.palette.text.secondary,
}));

export const StyledTypographyDetailValue = styled(Typography)(({ theme }) => ({
  ...DetailItemBaseStyles,
  color: theme.palette.text.primary,
}));

export const StyledStackRow = styled(Stack)({
  flexDirection: 'row',
  gap: '16px',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

export const StyledStackTitle = styled(Stack)({
  fontSize: '8px',
  flexDirection: 'row',
  gap: '8px',
  alignItems: 'center',
});
