import { Stack, Typography, ChipProps, useTheme } from '@mui/material';
import { CurrencyFormat } from '@securitize/reactjs-sid-utils';
import { useCallback, useContext, useMemo } from 'react';
import { ReactComponent as DollarIcon } from '../../../../assets/dollar.svg';
import { ReactComponent as EuroIcon } from '../../../../assets/euro.svg';
import {
  AccountStatus,
  CashBalance,
  CashBalanceSummary,
  Provider,
} from '../../../../interfaces/AccountSummary';
import { Translate } from '../../../../i18n/Translate';
import {
  TranslationsKeys,
  TranslationsKeyType,
  TranslationsType,
} from '../../../../i18n/translationsKeysType';
import {
  StyledBalanceDetail,
  StyledChip,
  StyledStackRow,
  StyledStackTitle,
  StyledTypographyDetailTitle,
  StyledTypographyDetailValue,
} from './styles';
import { Currency } from '../../../../interfaces/Currency';
import { DEFAULT_CURRENCY_LOCALE } from '../../../../i18n/locale';
import { GlobalContext } from '../../../../context/GlobalContext';

type CashBalanceDetailProps = {
  cashBalance: CashBalanceSummary;
  balance?: CashBalance;
};

const CashBalanceStatusColorMap: Record<AccountStatus, ChipProps['color']> = {
  [AccountStatus.CREATED]: 'success',
  [AccountStatus.PENDING]: 'warning',
  [AccountStatus.DRAFT]: 'default',
  [AccountStatus.CLOSED]: 'error',
  [AccountStatus.FROZEN]: 'error',
};

const IconMapper: Record<Currency, React.ReactNode> = {
  EUR: <EuroIcon />,
  USD: <DollarIcon />,
};

const getProviderTranslationTitleKey = (
  provider: Provider,
): TranslationsKeyType => {
  const translationKey = `OVERVIEW.BALANCE_DETAIL.PROVIDER_${provider}_TITLE`;

  if (TranslationsKeys.includes(translationKey as TranslationsKeyType)) {
    return translationKey as keyof TranslationsType;
  } else {
    return 'OVERVIEW.BALANCE_DETAIL.PROVIDER_DEFAULT_TITLE';
  }
};

export const CashBalanceDetail = ({
  cashBalance,
  balance,
}: CashBalanceDetailProps) => {
  const theme = useTheme();
  const { getTranslationByKey } = useContext(GlobalContext);

  const cashBalanceStatus = useMemo<string>(() => {
    const statusTranslationKey = `OVERVIEW.BALANCE_DETAIL.STATUS.${cashBalance.status}`;
    if (
      TranslationsKeys.includes(statusTranslationKey as TranslationsKeyType)
    ) {
      return getTranslationByKey(statusTranslationKey as TranslationsKeyType);
    }

    return '-';
  }, [cashBalance.status, getTranslationByKey]);

  const currencyFormat = useMemo(
    () =>
      new CurrencyFormat({
        currency: cashBalance.currency,
        overrideLocale: DEFAULT_CURRENCY_LOCALE,
      }),
    [cashBalance.currency],
  );

  const getBalanceDetail = useCallback(
    (
      balanceDetailKey: keyof Pick<
        CashBalance,
        'available' | 'locked' | 'total'
      >,
    ) => {
      return balance && balanceDetailKey
        ? currencyFormat.format(balance[balanceDetailKey])
        : '-';
    },
    [balance, currencyFormat],
  );

  return (
    <Stack sx={{ gap: '20px', width: '100%' }}>
      <StyledStackTitle>
        {IconMapper[cashBalance.currency]}
        <Typography
          variant="subtitle1"
          sx={{ fontSize: '16px', fontWeight: 500 }}
        >
          <Translate
            translationKey="OVERVIEW.BALANCE_DETAIL.TITLE"
            parameters={{ currency: cashBalance.currency.toUpperCase() }}
          />
        </Typography>
        <StyledChip
          label={cashBalanceStatus}
          variant="outlined"
          size="small"
          color={
            cashBalance.status
              ? CashBalanceStatusColorMap[cashBalance.status]
              : 'default'
          }
        />
      </StyledStackTitle>
      <StyledStackRow>
        <StyledBalanceDetail>
          <StyledTypographyDetailTitle
            variant="body1"
            sx={{ color: theme.palette.text.secondary }}
          >
            <Translate
              translationKey="OVERVIEW.BALANCE_DETAIL.TOTAL_TITLE"
              parameters={{ currency: cashBalance.currency.toUpperCase() }}
            />
          </StyledTypographyDetailTitle>
          <StyledTypographyDetailValue variant="body1">
            {getBalanceDetail('total')}
          </StyledTypographyDetailValue>
        </StyledBalanceDetail>
        <StyledBalanceDetail>
          <StyledTypographyDetailTitle variant="body1">
            <Translate translationKey="OVERVIEW.BALANCE_DETAIL.TOTAL_AVAILABLE_TITLE" />
          </StyledTypographyDetailTitle>
          <StyledTypographyDetailValue variant="body1">
            {getBalanceDetail('available')}
          </StyledTypographyDetailValue>
        </StyledBalanceDetail>
        <StyledBalanceDetail>
          <StyledTypographyDetailTitle variant="body1">
            <Translate translationKey="OVERVIEW.BALANCE_DETAIL.LOCKED_TITLE" />
          </StyledTypographyDetailTitle>
          <StyledTypographyDetailValue variant="body1">
            {getBalanceDetail('locked')}
          </StyledTypographyDetailValue>
        </StyledBalanceDetail>
      </StyledStackRow>
      <StyledStackRow>
        {cashBalance.providers.map((providerSummary) => (
          <StyledBalanceDetail
            key={`${cashBalance.currency}-${providerSummary.provider}`}
          >
            <StyledTypographyDetailTitle variant="body1">
              <Translate
                translationKey={getProviderTranslationTitleKey(
                  providerSummary.provider,
                )}
              />
            </StyledTypographyDetailTitle>
            <StyledTypographyDetailValue variant="body1">
              {providerSummary.providerId || '-'}
            </StyledTypographyDetailValue>
          </StyledBalanceDetail>
        ))}
      </StyledStackRow>
    </Stack>
  );
};
