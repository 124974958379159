import { useCallback, useEffect, useMemo } from 'react';
import { Card, Loader } from '@securitize/reactjs-cp-ui';
import { CardContent, CardHeader, styled } from '@mui/material';
import useRequestState from '../../../hooks/useRequestState';
import {
  AccountStatus,
  AccountSummary,
} from '../../../interfaces/AccountSummary';
import { getAccountSummary } from '../../../data/rest/account';
import { Currency } from '../../../interfaces/Currency';
import { CashBalanceDetail } from './CashBalanceDetail';
import { OverviewTitle } from './Title';

type OverviewProps = {
  investorId: string;
};

const StyledCardContent = styled(CardContent)({
  minHeight: '104px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Overview = ({ investorId }: OverviewProps) => {
  const [investorSummaryRequest, setInvestorSummaryRequest] =
    useRequestState<AccountSummary>();

  const getInvestorSummaryCallback = useCallback(
    async (investorId: string) => {
      setInvestorSummaryRequest({
        isLoading: true,
        data: undefined,
        error: null,
      });

      try {
        const data = await getAccountSummary(investorId);
        setInvestorSummaryRequest({
          data,
          isLoading: false,
        });
      } catch (error) {
        setInvestorSummaryRequest({
          error,
          isLoading: false,
        });
      }
    },
    [setInvestorSummaryRequest],
  );

  useEffect(() => {
    getInvestorSummaryCallback(investorId);
  }, [investorId, getInvestorSummaryCallback]);

  // TODO: We are filtering USD balance and cash balance because we are not using the other currencies
  // We should remove this filter when we start using the other currencies like EUR
  // Cash balances should be displayed dynamically based on the currency
  // https://securitize.atlassian.net/browse/ATS-2679
  const [usdCashBalance, usdBalance] = useMemo(() => {
    if (!investorSummaryRequest.data) {
      return [];
    }

    const cashBalance = investorSummaryRequest.data.cashBalances.find(
      (balance) => balance.currency === Currency.USD,
    );
    const balance = investorSummaryRequest.data.balance?.find(
      (balance) => balance.currency === Currency.USD,
    );

    return [cashBalance, balance];
  }, [investorSummaryRequest.data]);

  const isLoading = useMemo(
    () => investorSummaryRequest.isLoading || !usdCashBalance,
    [investorSummaryRequest.isLoading, usdCashBalance],
  );

  const usdConvertWithdrawDisabled = useMemo(
    () =>
      !usdCashBalance ||
      usdCashBalance.disabled ||
      usdCashBalance.status !== AccountStatus.CREATED,
    [usdCashBalance],
  );

  return (
    <Card>
      <CardHeader
        title={
          <OverviewTitle
            usdConvertWithdrawDisabled={usdConvertWithdrawDisabled}
            investorId={investorId}
          />
        }
      />
      <StyledCardContent>
        {isLoading && <Loader color="inherit" size={16} />}
        {!isLoading && (
          <CashBalanceDetail
            balance={usdBalance}
            cashBalance={usdCashBalance!}
          />
        )}
      </StyledCardContent>
    </Card>
  );
};
